import { HttpClient } from "@angular/common/http"
import { Injectable, OnDestroy } from "@angular/core"
import { Router } from "@angular/router"
import { BehaviorSubject, Observable, Subscription, of } from "rxjs"
import { catchError, finalize, map, switchMap, tap } from "rxjs/operators"
import { environment } from "src/environments/environment"
import { UserModel } from "../_models/user.model"
import { AuthHTTPService } from "./auth-http/auth-http.service"
import { LoginService } from "./login.service"

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [] // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`

  // public fields
  currentUser$: Observable<UserModel>
  isLoading$: Observable<boolean>
  currentUserSubject: BehaviorSubject<UserModel>
  isLoadingSubject: BehaviorSubject<boolean>
  redirectUrl: BehaviorSubject<string> = new BehaviorSubject("")
  mobileId: BehaviorSubject<string> = new BehaviorSubject(null)

  get currentUserValue(): UserModel {
    return this.currentUserSubject.value
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user)
  }

  constructor(private _Login: LoginService, private _http: HttpClient, private authHttpService: AuthHTTPService, private router: Router) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false)
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined)
    this.currentUser$ = this.currentUserSubject.asObservable()
    this.isLoading$ = this.isLoadingSubject.asObservable()
  }

  logout() {
    localStorage.clear()
    // this.router.navigate(['auth/login'])
    if (!environment.isDev) {
      window.location.href = 'https://userv1.4jawaly.com/auth/login';
    } else {
      this.router.navigate(["/auth/login"]);
    }
  }

  // private methods
  private setAuthFromLocalStorage(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.codeToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))
      return true
    }
    return false
  }

  private getAuthFromLocalStorage(): any {
    try {
      const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken))
      return authData
    } catch (error) {
      console.error(error)
      return undefined
    }
  }

  // public methods
  login(form, isSubAccount): Observable<any> {
    return this.authHttpService.login(form, isSubAccount)
  }

  // ========Register=========

  activeMobile(body, guestToken): Observable<any> {
    return this.authHttpService.getAccessToken(guestToken).pipe(
      map((res) => {
        const result = this.setAuthFromLocalStorage({ codeToken: res?.token, guestToken })
        return result
      }),
      switchMap(() => this.registerWithMobile(body))
    )
  }

  registerWithMobile(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage()
    if (!auth || !auth.codeToken) {
      return of(undefined)
    }
    return this.authHttpService.registerWithMobile(body, auth)
  }

  resendCodeToMobile(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage()
    if (!auth || !auth.codeToken) {
      return of(undefined)
    }
    return this.authHttpService.resendCodeToMobile(body, auth)
  }

  verifyCode(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage()
    if (!auth || !auth.codeToken) {
      return of(undefined)
    }
    return this.authHttpService.activeMobile(body, auth)
  }

  register(body): Observable<any> {
    const auth = this.getAuthFromLocalStorage()
    if (!auth || !auth.codeToken) {
      return of(undefined)
    }
    return this.authHttpService.setUserData(body, auth)
  }

  // ========Register=========

  verifyLoginWithUser(code) {
    const platform = localStorage.getItem("platform")
    return this.authHttpService.verifyLoginWith(code, platform).pipe(
      map((user: { item: UserModel; access_token: string }) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user.item)
          this.setAuthFromLocalStorage({ accessToken: user.access_token })
        } else {
          this.logout()
        }
        return user
      })
    )
  }

  loginWith(platform) {
    localStorage.setItem("platform", platform)
    return this.authHttpService.loginWith(platform).pipe(
      catchError((err) => {
        console.error("err", err)
        return of(undefined)
      })
    )
  }

  // public methods
  // To use when refresh token
  public changeUserAuthData(auth: any): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.codeToken) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth))
      return true
    }
    return false
  }

  getUserProfile() {
    const auth = this.getAuthFromLocalStorage()
    if (!auth || !auth.accessToken) {
      return of(undefined)
    }

    return this.authHttpService.getUserProfile(auth.accessToken).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user)
        } else {
          this.logout()
        }
        return user
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  /* *@param */
  getUserBalance(): Observable<any> {
    const url = `${environment.apiUrl}/account/profile/balance`
    return this._http.get(url)
  }

  changePassword(body, isSub?): Observable<any> {
    const url = !isSub ? `${environment.apiUrl}/account/profile/change-password` : `${environment.apiUrl}/account/profile/sub-update-password`
    return !isSub ? this._http.post(url, body) : this._http.patch(url, body)
  }

  // Forget Password

  sendEmailToUserToResetPassword(body): Observable<any> {
    const url = `${environment.apiUrl}/account-reset`
    return this._http.post(url, body)
  }

  verifyCodeAfterSendMail(body): Observable<any> {
    const url = `${environment.apiUrl}/account-reset/verify`
    return this._http.post(url, body)
  }

  resendMailToReset(body): Observable<any> {
    const url = `${environment.apiUrl}/account-reset/resend-verify`
    return this._http.post(url, body)
  }

  resendCodeToPhone(body): Observable<any> {
    const url = `${environment.apiUrl}/account-reset/resend-verify-user-sms`
    return this._http.post(url, body)
  }

  /* *@param */
  refreshToken() {
    const url = `${environment.apiUrl}/auth/refresh`
    return this._http.post(url, {})
  }

  /* *@param */
  getUserProfileData() {
    const url = `${environment.apiUrl}/account/profile`
    return this._http.get(url).pipe(
      map((res: any) => {
        if (res?.main_account) {
          res.item["main_account"] = res?.main_account
        }

        // ? If user doesn't scan googleAuth QrCode and (active_google_verify) is 1 ==> We will change the active_google_verify to 0
        if (res?.item?.active_google_verify && !res?.item?.auth_secret) {
          res.item.active_google_verify = 0
        }
        return {
          ...res,
          item: {
            ...res.item,
            senders_active: res?.main_account ? -1 : res.senders_active,
            senders_expired: res.senders_expired,
            current_sub_accounts: res.current_sub_accounts,
          },
        }
      }),
      tap((res: any) => {
        localStorage.setItem("user", JSON.stringify(res?.item))
      })
    )
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe())
  }
}
