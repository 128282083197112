export interface BasePaginateRes {
    current_page?: number,
    first_page_url?: string,
    from?: number,
    last_page?: number,
    last_page_url?: string,
    links?: any[],
    next_page_url?: string,
    path?: string,
    per_page?: number,
    prev_page_url?: null,
    to?: number,
    total?: number,
}

export interface MediaFilters {
    order_by?: 'newest_first' | 'oldest-first' | 'most-liked' |
    'lowest_likes' | 'most_viewed' | 'lowest_viewed' |
    'most_comments' | 'lowest_comments' | 'random',
    user_id?: any,
    title?: any,
    description?: any,
    search_word?: any,
    type?: 'video' | 'photo',
    take?: any,
    latest?: any,
    latest_type?: 'day' | 'week' | 'month' | 'year',
    tag?: any,
    page?: number,
}

export const cellActionStyle = {
    color: '#1cced8',
    backgroundColor: '#d2f5f7',
    'font-size': '0.925rem',
    'line-height': '1.35',
    'border-radius': '0.42rem',
    'height': '30px',
    'width': '30px',
    'border': 'none'
};

export const MoreActionStyle = {
    'min-width': ' 30px',
    'height': '30px',
    'display': 'flex',
    'align-items': ' center',
    'justify-content': ' center',
    backgroundColor: '#2288d3'
}

export const DeleteActionStyle = {
    ...cellActionStyle,
    backgroundColor: '#f2cad2',
    color: '#ea657f'
};


export enum TableActions {
    edit = 'edit',
    changeStatus = 'CHANGE_STATUS',
    delete = 'delete',
    accept = 'accept',
    add = 'add',
    isDefault = 'isDefault',
    show = 'visibility',
    download = 'download',
    more = 'more',
    printContract = 'print',
    contractInvoices = 'wallpaper',
    uploadModel = 'cloud_upload',
    cancelSubscription = 'cancel-subscription',
    salePackage = 'sale-package',
    paymentMethodPermission = 'perm_contact_calendar',
    accountGuidePermission = 'accessibility',
    approve = 'approve',
    refuse = 'refuse',
    refuseReason = 'refuseReason',
    floors = 'floors',
    select = 'select',
    connectToDevice = 'connect-finder-device',
    bootSend = 'add-send-boot',
    bootReceive = 'add-receive-boot',
    bootAction = 'add-action-boot',
}

export enum SystemServicesTypes {
    whats = 'whats',
    sms = 'sms',
    erp = 'erp',
    crm = 'crm',
    hosting = 'hosting',
}

export enum WhatsMessageType {
    ptt = 'ptt',
    chat = 'chat',
    vcard = 'vcard',
    link = 'link',
    forward = 'forward',
    location = 'location',
    text = 'text',
    file = 'file',
    contact = 'contact',
    groupShare = 'groupShare',
}

export enum WhatsPaymentMethodIds {
    Stripe = 2,
    hyperPay = 3,
    Knet = 4,
    fromBalance = 1,
    bankTransfer = 5,
}

export enum BanksPayment {
    bankRajhi = 1,
    bankAlAhli = 2,
    bankAlRiad = 3,
    bankAlEnmaa = 4,
}

export enum HttpErrorResponseStatus {
    unauthorized = 401, // token expired
    forbidden = 403, // not allowed
    notFound = 404, // not found
    internalServerError = 500, // server error
    badRequest = 400, // validation
    conflict = 409, // validation
    unprocessableEntity = 422, // validation
    tooManyRequests = 429, // validation
    serviceUnavailable = 503, // validation
    
}

export enum ControlsTypes {
    text = 'text',
    number = 'number',
    email = 'email',
    password = 'password',
    textarea = 'textarea',
    select = 'select',
    radio = 'radio',
    checkbox = 'checkbox',
    date = 'date',
    time = 'time',
    file = 'file',
    image = 'image',
}

export interface GlobalAgTableColumns {
    headerName?: string,
    valueGetter?: string,
    cellRenderer?: string,
    cellClass?: string,
    cellRendererParams?: any,
    filter?: boolean,
    width?: number,
}

export const AllowedUploadedFile = ['png', 'jpg', 'apng', 'pdf', 'txt', 'xls'
    , 'avif', 'gif', 'jpeg', 'pjpeg', 'webp', 'svg', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'wav', 'mp3', 'mp4',
    'PNG', 'JPG', 'APNG', 'PDF', 'TXT', 'XLS'
    , 'AVIF', 'GIF', 'JPEG', 'PJPEG', 'WEBP', 'SVG', 'XLSX', 'ZIP', 'RAR', 'DOC', 'DOCX', 'WAV', 'MP3', 'MP4']


export const AllowedUploadedFileTicket = ['png', 'jpg', 'apng', 'pdf', 'avif'
    , 'gif', 'jpeg', 'pjpeg', 'webp', 'svg', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'wav', 'mp3', 'mp4',
    'PNG', 'JPG', 'APNG', 'PDF', 'AVIF', 'GIF', 'JPEG', 'PJPEG', 'WEBP', 'SVG', 'XLSX', 'ZIP', 'RAR', 'DOC', 'DOCX', 'WAV', 'MP3', 'MP4']

export const AllowedUploadedFileDepositReceipt = ['png', 'jpg', 'apng', 'pdf'
    , 'gif', 'jpeg', 'pjpeg', 'webp', 'svg', 'xlsx', 'zip', 'rar', 'doc', 'docx','PNG', 'JPG', 
    'APNG', 'PDF', 'GIF', 'JPEG', 'PJPEG', 'WEBP', 'SVG', 'XLSX', 'ZIP', 'RAR', 'DOC', 'DOCX']


export enum MessageTypes {
    wanning = 'wanning',
    success = 'success',
    error = 'error',
    noData = 'noData',
    info = 'info',
}
export enum Sources {
    jwaly = '4jawaly.net',
    jwalyCom = '4jawaly.com',
}

export enum GlobalErrorCodes {
    ipNotValid = '3003',
    setMobileCode = '3001',
    setEmailCode = '3002',
    setMobileCodeSubAccount = '3005',
    NeedVerifyGoogleAuth = '3008',
    AcceptAgreement = '3004',
    userNotFound = '3006',
    SubUerChangePassword = '3007',
    NeedToAddDevice = '4000',
    AddDeviceVerifyMobile = '4001',
    AddDeviceVerifyEmail = '4002',
    AddDeviceVerifyGoogle = '4008',
    AddDeviceVerifySubMobile = '4005',
}

export const EtqanAppIdNumber = 202211100398130;
export const allCountriesIso = ['SA', 'EG', 'DZ', 'IQ', 'MA', 'SD', 'TN', 'LY', 'JO', 'PS', 'YE', 'OM', 'QA', 'KW', 'AE', 'BH', 'SY', 'LB', 'MR', 'SO', 'DJ', 'ER', 'ET', 'KE', 'MG', 'MU', 'SC', 'SO', 'TZ', 'UG', 'ZM', 'ZW', 'AO', 'BF', 'BI', 'BJ', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'CV', 'DJ', 'DZ', 'EG', 'EH', 'ER', 'ET', 'GA', 'GH', 'GM', 'GN', 'GQ', 'GW', 'KE', 'KM', 'LR', 'LS', 'LY', 'MA', 'MG', 'ML', 'MR', 'MU', 'MW', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SC', 'SD', 'SH', 'SL', 'SN', 'SO', 'ST', 'SZ', 'TD', 'TG', 'TN', 'TZ', 'UG', 'YT', 'ZA', 'ZM', 'ZW', 'AO', 'BF', 'BI', 'BJ', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'CV', 'DJ', 'DZ', 'EG', 'EH', 'ER', 'ET', 'GA', 'GH', 'GM', 'GN', 'GQ', 'GW', 'KE', 'KM', 'LR', 'LS', 'LY', 'MA', 'MG', 'ML', 'MR', 'MU', 'MW', 'MZ', 'NA', 'NE', 'NG', 'RE', 'RW', 'SC', 'SD', 'SH', 'SL', 'SN', 'SO', 'ST', 'SZ', 'TD', 'TG', 'TN', 'TZ', 'UG', 'YT', 'ZA', 'ZM', 'ZW', 'AO', 'BF', 'BI', 'BJ', 'BW', 'CD', 'CF', 'CG', 'CI', 'CM', 'CV', 'DJ', 'DZ', 'EG', 'EH', 'ER', 'ET', 'GA'];

export const TimeZoneList = [
    {
        title: "التوقيت العالمي UTC",
        zone : "UTC"
    },
    {
        title: "مصر",
        zone : "Africa/Cairo",
    },
    {
        title: "الجزائر",
        zone : "Africa/Algiers"
    },
    {
        title: " السودان",
        zone : "Africa/Khartoum",
    },
    {
        title: " العراق",
        zone : "Asia/Baghdad",
    },
    {
        title: " المغرب",
        zone : "Etc/GMT+1"
    },
    {
        title: " السعودية",
        zone : "Asia/Riyadh",
    },
    {
        title: " اليمن",
        zone : "Asia/Riyadh" // -*****
    },
    {
        title: " سوريا",
        zone : "Asia/Damascus",
    },
    // {
    //     title: " الصومال",
    //     zone : "" // -*****
    // },
    {
        title: " تونس",
        zone : "Africa/Tunis"
    },
    {
        title: " الإمارات العربية المتحدة",
        zone : "Asia/Dubai",
    },
    {
        title: " الأردن",
        zone : "Asia/Amman",
    },
    {
        title: " ليبيا",
        zone : "Africa/Tripoli",
    },
    {
        title: " فلسطين",
        zone : "Asia/Riyadh" // -*****
    },
    {
        title: " عمان",
        zone : "Asia/Riyadh" // -*****
    },
    // {
    //     title: " تركيا",
    //     zone : "Etc/GMT+3",
    // },
    {
        title: " الولايات المتحدة الامريكية",
        zone : "America/Chicago",
    },
]

export const authority = false;

export enum AppModules {
    dashboard = 'dashboard',
    salla = 'salla',
}

export enum ContractStatus {
    pending = '0',
    pendingApproved = '1',
    approved = '2',
    refused = '3',
    payed = '4',
    expired = '5',
}

export enum CustomerTypes {
    governorate = 1,
    charity = 2,
    special = 3,
    global = 4,
    individula = 5
}

export enum SendersRequestStatus {
    needPay = 2,
    NeedPayV2 = 7,
    needPayV3 = 0,
    needRenew = 4,
    finishedSender = 5,
    RefusedByHwsabah = 3
}

export enum whtasPackagesType {
    official = 1,
    lite = 2
}
export const DinarLibyan = "LYD";
export const DirhamEmirates = "AED";
export const DinarKuwait = "KWD";