import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http"
import {Injectable} from "@angular/core"
import {Router} from "@angular/router"
import {CookieService} from "ngx-cookie-service"
import {NgxSpinnerService} from "ngx-spinner"
import {BehaviorSubject, throwError} from "rxjs"
import {catchError, filter, switchMap, take} from "rxjs/operators"
import {AuthService} from "src/app/modules/auth/_services/auth.service"
import {GoogleSheetService} from "src/app/modules/google-sheet/data-access/services/google-sheet.service"
import {Constant} from "src/app/shared/config/constant"
import {HttpErrorResponseStatus} from "src/app/shared/models/shared"
import {BrowserDbService} from "src/app/shared/services/browser-db.service"
import {HelperService} from "src/app/shared/services/helpers.service"
import {environment} from "src/environments/environment"

@Injectable({
    providedIn: "root",
})
export class authInterCeptor implements HttpInterceptor {
    private isRefreshing = false
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor(private router: Router,
                private _AuthService: AuthService,
                private spinner: NgxSpinnerService,
                private _Helpers: HelperService,
                private browserDbService: BrowserDbService,
                private googleSheetService: GoogleSheetService,
                private cookieService: CookieService,
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        let token = this.browserDbService.getItem(Constant.token);
        const cookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('4Jawaly='));
        if (!token && cookie) {
            token = cookie ? cookie.split('=')[1] : null;
            this.browserDbService.setItem('token', token);
        } else if ((token !== cookie) && cookie) {
            token = cookie ? cookie.split('=')[1] : null;
            this.browserDbService.setItem('token', token);
        }
        if (token) {
            return next.handle(this.handleAuthToken(request)).pipe(
                catchError((error) => {
                    if (error instanceof HttpErrorResponse && error.status === HttpErrorResponseStatus.unauthorized) {
                        if (request.url.includes('https://servershandler.4ja.ai') || request.url.includes(environment.whats.newBoot)) {
                            return throwError(error)
                        }
                        return this.handelRefreshToken(request, next)
                    }
                    return throwError(error)
                })
            )
        }
        return next.handle(request.clone({
            setHeaders: {
                'From': this.cookieService.get("from_key"),
            }
        }))
    }

    private handelRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true
            this.refreshTokenSubject.next(null)
            return this._AuthService.refreshToken().pipe(
                switchMap((res: any) => {
                    this.spinner.hide()
                    this.isRefreshing = false
                    this.browserDbService.setItem(Constant.token, res?.access_token)
                    document.cookie = `4Jawaly=${res?.access_token}; domain=.4jawaly.com; path=/; secure; samesite=None`;
                    document.cookie = `4JawalyOut=; domain=.4jawaly.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC`;
                    this.refreshTokenSubject.next(res?.access_token)
                    return next.handle(request)
                }),
                catchError((err) => {
                    this.spinner.hide()
                    this.isRefreshing = false
                    // let clearedData = ["user", "token", "userData"]
                    // clearedData.forEach((element) => {
                    //   localStorage.removeItem(element)
                    // })
                    // this.router.navigate(["auth/login"])
                    return throwError(err)
                })
            )
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    return next.handle(this.handleAuthToken(request));
                })
            );
        }
    }


    private handleAuthToken(request: HttpRequest<any>): HttpRequest<any> {

        const ignoredUrls = ["no-token", "dh-api", environment.excelApiUrl, "resellers", "chapi", 'chatbot']
        const url = request.url
        if (url.includes(environment.excelApiUrl)) {
            return this.setGoogleSheetToken(request)
        }
        if (url.includes(environment.whats.newBoot)) {
            return request
        }
        if (url.includes('https://bbot')) {
            return request
        }
        if (url.includes('https://servershandler.4ja.ai')) {
            return request
        }
        if (url.includes("chapi") && this.browserDbService.getItem(Constant.whatsToken)) {
            return this.setChatBootleToken(request)
        }
        if (url.includes('https://chatbot.4ja.ai')) {

            return this.setSkunkumChatBootleToken(request)
        }
        if (!ignoredUrls.some((ig) => url.includes(ig))) {
            return this.setBearerToken(request)
        }
        return request
    }

    private setBearerToken(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                "ngsw-bypass": "true",
                'From': this.cookieService.get("from_key"),
                Authorization: `Bearer ${this.browserDbService.getItem(Constant.token)}`,
            },
        })
    }

    private setGoogleSheetToken(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                "ngsw-bypass": "true",
                'From': this.cookieService.get("from_key"),
                Authorization: `Basic ${this.googleSheetService.getApiKeySecret()}`,
                "Content-Type": "application/json",
            },
        })
    }

    private setChatBootleToken(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.browserDbService.getItem(Constant.whatsToken)}`,
                // "Content-Type": "application/json",
            },
        })
    }

    private setSkunkumChatBootleToken(request: HttpRequest<any>) {
        return request.clone({
            setHeaders: {
                "Content-Type": "application/json",
                Authorization: `Basic Mm5DMGEwTWU4aURRUVJBemZobjF3UnlEdVUwSmREbTFpOXVBQ29pVjpmMGNQYU5uaWN1TUlEd092TExtUzhUN3pDTjFhQTU4OXYzanVLbnU2Z0pUV0RSOFJMSFN6WjlCY01sN2E4SGFxVzlubzFSRzdtRDB3UWl5TXhuSVJRQWVhbDM4akhSQ2RQUERL`,
            },
        })
    }
}
