import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {environment} from '../../../../environments/environment';
@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!environment.isDev) {
      if (route.url[0]?.path === 'auth') {
        window.location.href = 'https://userv1.4jawaly.com/auth/login';
        return false;
      }
    }
    return true;
  }
}
