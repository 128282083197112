import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from "@angular/service-worker";
// import { SwUpdate } from "@angular/service-worker";

@Injectable({
  providedIn: 'root'
})
export class CheckForUpdateServiceService {

  constructor(private appRef: ApplicationRef, 
    private updates: SwUpdate
  ) {

  }

  update() {
    this.updates.available.subscribe(event => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
