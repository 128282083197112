import { ApplicationRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from "@angular/service-worker";
import { ToastrService } from "ngx-toastr";
import { interval, Subscription } from 'rxjs';
import { environment } from "../environments/environment";
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { AuthService } from "./modules/auth";
import { LoginService } from "./modules/auth/_services/login.service";
import { locale as arLang } from './modules/i18n/vocabs/ar';
import { locale as enLang } from './modules/i18n/vocabs/en';
import { Constant } from "./shared/config/constant";
import { QueryParamKeys } from "./shared/enums/query-params-kys.enum";
import { BrowserDbService } from "./shared/services/browser-db.service";
import { CheckForUpdateServiceService } from "./shared/services/check-for-update-service.service";
import { PermissionService } from "./shared/services/permission.service";
import { SharedService } from "./shared/services/shared.service";
import { DynamicServiceService } from "./shared/services/title/dynamic-service.service";

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
    private storageListener: (event: StorageEvent) => void;
    userSettings = {};
    private unsubscribe: Subscription[] = [];
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private updates: SwUpdate,
        private titleService: Title,
        private _login: LoginService,
        private _Shared: SharedService,
        private appRef: ApplicationRef,
        private authService: AuthService,
        private _ActiveRoute: ActivatedRoute,
        private _Permissions: PermissionService,
        private tableService: TableExtendedService,
        private browserDbService: BrowserDbService,
        private _TitleService: DynamicServiceService,
        @Inject(DOCUMENT) private document: Document,
        private _Update: CheckForUpdateServiceService,
        private translationService: TranslationService,
        private splashScreenService: SplashScreenService,
        private toastr: ToastrService,
    ) {
        // register translations
        this.translationService.loadTranslations(
            enLang,
            arLang,
        );
    }

    ngOnInit() {
        this.storageListener = (event: StorageEvent) => {
            if (event.key === 'logout') {
                this.toastr.error('لقد تم تسجيل خروجك من نافذة أخرى. يرجى تسجيل الدخول مرة أخرى.');
                if (!environment.isDev) {
                    setTimeout(() => {
                        window.location.href = 'https://userv1.4jawaly.com/';
                    }, 4000);
                }
            }
        };
        window.addEventListener('storage', this.storageListener);
        this._TitleService.UpdatingPageTitleDynamically();
        setTimeout(() => {
            this.checkForUpdates();
            this.setUpdate();
        }, 2000);

        this._Shared.userGlobalSetting.subscribe(res => {
            this.userSettings = res;
        })

        if (this.browserDbService.getItem(Constant.user)) {
            this.authService.currentUserSubject.next(this.browserDbService.getItem(Constant.user));
            this.initializeUserPermissions(this.browserDbService.getItem(Constant.user));
        }

        this.changeDire('ar');

        let currentLang = this.browserDbService.getItem(Constant.language) ? this.browserDbService.getItem(Constant.language) : 'ar';
        this.translationService.setLanguage(currentLang);
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkIfUserLoginAsSuperAdmin();
                // clear filtration paginations and others
                this.tableService.setDefaults();
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });
        this.getIP();
        this.unsubscribe.push(routerSubscription);
    }

    checkIfUserLoginAsSuperAdmin() {
        if (this._ActiveRoute.snapshot.queryParams[QueryParamKeys.LoginAsSupportAdminToken]) {
            this.browserDbService.setItem(Constant.token,
                this._ActiveRoute.snapshot.queryParams[QueryParamKeys.LoginAsSupportAdminToken]);
            this.browserDbService.setItem(Constant.user, {});
            this.browserDbService.setItem(Constant.isLoginFromAdmin, true);
            this.browserDbService.removeItem(Constant.whatsToken);
            this.browserDbService.removeItem(Constant.whatsBootSecretKey);
            this.browserDbService.removeItem(Constant.whatsBootToken);
            this.router.navigate(['/dashboard']);
        }
    }

    initializeUserPermissions(userData) {
        if (!userData) return;
        const permissions = this._Permissions.getPermissionList(userData);
        this._Permissions.initializeUserPermissions(permissions);
    }

    getIP() {
        this._login.getIPAddress().subscribe((res: any) => {
            this._Shared.userGlobalSetting.next({
                ...this.userSettings,
                countryCode: res.countrycode.toLocaleLowerCase()
            })
        });
    }

    changeDire(lang) {
        const htmlTag = this.document.getElementsByTagName(
            "html"
        )[0] as HTMLHtmlElement;
        htmlTag.dir = lang === "ar" ? "rtl" : "ltr";
        htmlTag.lang = lang;
        htmlTag.setAttribute("direction", `${lang === "ar" ? "rtl" : "ltr"}`);
    }

    checkForUpdates() {
        this.appRef.isStable.subscribe(stable => {
            if (stable) {
                const everySixHours$ = interval(6 * 60 * 60 * 1000);
                everySixHours$.subscribe(res => {
                    this.updates.checkForUpdate().then(() => {

                    });
                });
            }
        })
    }

    setUpdate() {
        this._Update.update();
    }

    ngOnDestroy() {
        window.removeEventListener('storage', this.storageListener);
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
        this.subscription.unsubscribe();
    }

}
