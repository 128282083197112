import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Constant } from "src/app/shared/config/constant";
import { QueryParamKeys } from "src/app/shared/enums/query-params-kys.enum";
import { RoutingPathsEnum } from "src/app/shared/enums/routing.enum";
import { BrowserDbService } from "src/app/shared/services/browser-db.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { AppInsightService } from "src/app/shared/utilities/app-insight.service";
import {environment} from "../../../../environments/environment";

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private shared: SharedService,
    private _AppInsight: AppInsightService,
    private browserDbService: BrowserDbService
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let token = this.browserDbService.getItem(Constant.token);
    const cookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('4Jawaly='));
    if (!token && cookie){
      token = cookie ? cookie.split('=')[1] : null;
      this.browserDbService.setItem('token', token);
    } else if ((token !== cookie) && cookie) {
      token = cookie ? cookie.split('=')[1] : null;
      this.browserDbService.setItem('token', token);
    }
    let isExpired =  true;
    if (token){
      isExpired = this.shared.tokenExpired(token);
    }
    const cookieOut = document.cookie
        .split('; ')
        .find(row => row.startsWith('4JawalyOut='));
    const logOut = cookieOut ? cookieOut.split('=')[1] : null;
    if (token && !isExpired && logOut !== '1') {
      // logged in so return true
      return true;
    }
    this._AppInsight.logException('logout',{
      message : 'log_out',
      isExpired : isExpired
    });
    localStorage.clear();
    if (route.queryParams[QueryParamKeys.ConfirmChangeData] && route.queryParams[QueryParamKeys.ChangeToken]) {

      this.router.navigate([RoutingPathsEnum.Gust], {
        queryParams: {
          [QueryParamKeys.ConfirmChangeData]: route.queryParams[QueryParamKeys.ConfirmChangeData],
          [QueryParamKeys.ChangeToken]: route.queryParams[QueryParamKeys.ChangeToken],
          [QueryParamKeys.Email]: route.queryParams[QueryParamKeys.Email],
          [QueryParamKeys.WhatsMobile]: route.queryParams[QueryParamKeys.WhatsMobile],
          [QueryParamKeys.Mobile]: route.queryParams[QueryParamKeys.Mobile]
        },
      });
      return false;
    }
    if (!environment.isDev) {
      window.location.href = 'https://userv1.4jawaly.com/auth/login';
    } else {
      this.router.navigate(["/auth/login"]);
    }
    return false;
  }
}
